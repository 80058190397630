<template>
  <div id="loginPage">
    <nav
      class="navbar navbar-expand-md navbar-dark bg-transparent"
      id="loginNav"
    >
      <div
        class="
          navbar-collapse
          collapse
          w-100
          order-1 order-md-0
          dual-collapse2
          ml-4
        "
      >
        <!-- <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
                <a class="nav-link" href="#">Left</a>
            </li>
        </ul> -->
        <img
          class=""
          alt="Mandaya Logo"
          style="height: 30px"
          src="../assets/careDokterWhite.png"
        />
      </div>
      <div class=" order-3 dual-collapse2 mr-3">
        <ul class="navbar-nav ml-auto">
          <!-- <li class="nav-item m-0 p-0 mt-1 mr-2">
          </li> -->
          <li class="nav-item dropdown">
            <div
              class="dropdown show"
              style="display: inline-flex;"
            >
              <span class="mr-2 mt-1">{{ $store.state.userName }}</span>
              <i
                class="far fa-user-circle c-pointer"
                style="font-size: 2em; color:grey;"
                id="dropdownMenuLink"
                data-toggle="dropdown"
              >
                <!-- <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    </a> -->
              </i>

              <div
                class="dropdown-menu"
                aria-labelledby="dropdownMenuLink"
                right
              >
                <!-- <a
                  class="dropdown-item"
                  href="#"
                  @click.prevent="$router.push('/pengaturan')"
                  ><i class="fas fa-cog mr-1"></i>Pengaturan</a
                > -->
                <a
                  class="dropdown-item"
                  target="_blank"
                  :href="accountUrl"
                  ><i class="fas fa-cog mr-1"></i>Pengaturan Account</a
                >
                <a class="dropdown-item" href="#" @click.prevent="logout()"
                  ><i class="fas fa-sign-out-alt mr-1"></i>Logout</a
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- <div class="navbar-collapse collapse w-100 order-3 dual-collapse2 mr-4">
        <ul class="navbar-nav ml-auto" id="masukDaftar">
          <li class="nav-item">
            <a
              :class="'nav-link ' + route(1)"
              href="#"
              @click.prevent="routeChange('LoginPage')"
              >Masuk</a
            >
          </li>
          <li class="nav-item">
            <a
              :class="'nav-link ' + route(2)"
              href="#"
              @click.prevent="routeChange('RegisterPage')"
              >Daftar</a
            >
          </li>
        </ul>
      </div> -->
    </nav>

    <div class="d-flex justify-content-start pt-3">
      <img
        class="imageBar"
        alt="Mandaya Logo"
        style="height: 4rem"
        src="../assets/careDokterWhite.png"
      />
      <div class="text-right w-100 mobile-profile">
        <ul class="nav justify-content-end">
          <!-- <li class="nav-item m-0 p-0 mt-1 mr-2">
          </li> -->
          <li class="nav-item mr-2">
            <div
              class="dropdown show"
              style="display: inline-flex;"
            >
              <span class="mr-2 mt-1">{{ $store.state.userName }}</span>
              <i
                class="far fa-user-circle c-pointer "
                style="font-size: 2em; color:grey;"
                id="dropdownMenuLink"
                data-toggle="dropdown"
              >
              </i>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenuLink"
                right
              >
                <a
                  class="dropdown-item"
                  target="_blank"
                  :href="accountUrl"
                  ><i class="fas fa-cog mr-1"></i>Pengaturan Account</a
                >
                <a class="dropdown-item" href="#" @click.prevent="logout()"
                  ><i class="fas fa-sign-out-alt mr-1"></i>Logout</a
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
     
    </div>
    <!-- <div class="container mt-5 pt-5"> -->
    <div class="row p-5 firstRow">
      <div class="col-12 col-sm-6 mt-3 text-left welcomeText">
        <h1><b>Selamat Datang</b></h1>
        <h1><b>Sahabat Mandaya!</b></h1>
        <!-- <div class="des-log">
          <h5>Jika anda tidak memiliki akun</h5>
          <h5>
            Anda dapat mendaftar di
            <b
              class="c-pointer"
              @click.prevent="$router.push({ name: 'RegisterPage' })"
              ><u> sini</u></b
            >
          </h5>
        </div> -->
      </div>

      <!-- <router-view /> -->
      <register />
      <!-- lupa password -->

      <!-- konfirmasi reset password -->
      <!-- <iframe src="https://fluttertemp2fordeployed2.web.app/#/" frameborder="1" width="400" height="400"></iframe> -->

    </div>
    <!-- </div> -->
<!-- 
    <div class="bottomBar">
      <div class="d-flex justify-content-center">
        <a
          :class="'nav-link ' + route(1)"
          href="#"
          @click.prevent="routeChange('LoginPage')"
          >Masuk</a
        >
        <a
          :class="'nav-link ' + route(2)"
          href="#"
          @click.prevent="routeChange('RegisterPage')"
          >Daftar</a
        >
      </div>
    </div> -->
  </div>
</template>
<script>
import axios from "@/axios";
import '@/plugin/keycloak';
import Register from '@/components/login/Register'
// import Keycloak from 'keycloak-js'
// const keycloak = Keycloak(require('@/assets/keycloak.json'))
export default {
  components: {
    Register
  },
  data() {
    return {
      accountUrl: process.env.VUE_APP_URL_SSO + '/realms/'+process.env.VUE_APP_REALM_SSO+'/account',
      loginForm: "login",
      password: "",
      userName: "",
      accessToken: "",
      refreshToken: "",
      kategories: [],
      kategori: "",
      email: "",
      namaDepan: "",
      namaBelakang: "",
      password: "",
      konfirmasiPassword: "",
      persetujuanEula: true,
      telepon: "",
      tipeAlamat: "",
      alamat: "",
      jalan: "",
      Konpassword: "",
      kodeVer: "",
      regisId: "",
      loading: false,
      isDisabled: false,
    };
  },
  watch: {},
  methods: {
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem('showKeycloak')
      setTimeout(() => {
        keycloak.logout({"redirectUri": window.location.origin})
      }, 100);
    },
    clickInit() {
      this.$keycloak.init({ onLoad:  'login-required'}).then((auth) => {
          console.log(this.$keycloak)
      })
      // window.SetKeycloak()
      // this.$store.dispatch('SSO_KEYCLOAK')
      // Vue.$keycloak
      //   .init({
      //       onLoad: 'login-required',
      //   })
      //   .then(data =>  data)
      //   .then((authenticated) => {
      //     console.log('data', authenticated, Vue.$keycloak.token)
      //   })
      //   .catch(err => console.log('err'))
      //   .finally(data => console.log('data', data))
      // console.log('click')
      // let initOptions = {
      //   url: 'https://daccount2.mandayamedical.group/auth',
      //   realm: 'caredokters4',
      //   clientId: 'web-referral',
      //   onLoad  : 'login-required'
        
      // }
      //   // console.log(keycloak)
      //   // cons
      //   keycloak.init({ onLoad: initOptions.onLoad, promiseType: 'native' }).then((auth) => {
      //     alert(keycloak.token)
      //     console.log('test', auth)
      //     console.log('data', keycloack.token)
      //   })
      //   .catch(err => {
      //     console.log(err)

      //   })
        // keycloak.init({onload : initOptions.onLoad}).then(function(authenticated) {
        //     alert(authenticated ? 'authenticated' : 'not authenticated');
        // }).catch(function() {
        //     alert('failed to initialize');
        // });
    },
    routeChange(status) {
      this.$router.push({ name: status }).catch((err) => {});
    },
    checkClassWithRoute(route) {
      if (route == "LoginPage") return 1;
      else if (route == "RegisterPage") return 2;
      return 0;
    },
    nameKeydown(e) {
      // console.log(/^[^\W_]+$/.test(e.key));
      // console.log(/^[_.@a-z0-9-]*$/.test(e.key));
      if (/^[_.@a-z0-9-]*$/.test(e.key)) {
      } else {
        e.preventDefault();
      }
    },
    route(rut) {
      if (rut == this.checkClassWithRoute(this.$route.name)) {
        return "active";
      }
      return "";
    },

    getauth() {
      axios
        .get(`/api/auth/true`, {
          // withCredentials: true,
          headers: {
            // "cookie": `access_token=${this.accessToken}; session_token=${this.refreshToken}`,
            Authorization: `Bearer ${this.accessToken}`,
          },
        })
        .then(({ data }) => {
          this.getToken();
          // console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getToken() {
      axios
        .get(`/mandayauaa/api/account`, {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        })
        .then(({ data }) => {
          this.$router.push("/");
          // console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    whichForm(form) {
      this.loginForm = form;
    },
    loging() {
      this.$router.push("/");
    },
  },
  mounted() {
  },
};
</script>
<style>
.pointer {
  cursor: pointer;
}
@media screen and (max-height: 500px) {
  .bottomBar {
    display: none !important;
  }
}
#loginPage {
  background: rgb(47, 144, 176);
  background: linear-gradient(39deg, rgb(176 47 120) 0%, rgb(64 10 199) 100%);
  min-height: 100vh;
}

.welcomeText h1,
.welcomeText h5 {
  color: whitesmoke;
}

.active {
  border-bottom: 5px solid whitesmoke;
}
</style>
