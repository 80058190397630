<template>
  <div class="col-12 col-sm-6 text-left registrationText welcomeText">
    <h1><b>Daftar</b></h1>
    <div class="reg-form">
      <form @submit.prevent="registrasis">
        <div class="form-group mt-3">
          <label class="text-white" for="exampleFormControlSelect1"
            >Nama Depan *</label
          >
          <input
            type=""
            class="form-control"
            :class="invalid.namaDepan"
            aria-describedby=""
            placeholder="Nama Depan"
            ref="namdep"
            id="namdep"
            v-model="namaDepan"
          />
          <small class="text-danger" v-if="invalid.namaDepan"
            >silahkan isi nama depan</small
          >
        </div>
        <div class="form-group mt-3">
          <label class="text-white" for="exampleFormControlSelect1"
            >Nama Belakang *</label
          >
          <input
            type=""
            class="form-control"
            :class="invalid.namaBelakang"
            aria-describedby=""
            ref="nambelakang"
            placeholder="Nama Belakang"
            v-model="namaBelakang"
          />
          <small class="text-danger" v-if="invalid.namaBelakang"
            >silahkan isi nama belakang</small
          >
        </div>

        <div class="form-group mt-3 fullWidth">
          <label class="text-white" for="exampleFormControlSelect1"
            >Kategori *</label
          >
          <select
            class="form-control"
            v-model="kategori"
            :class="invalid.kategori"
            ref="kategori"
          >
            <option v-for="kate in kategories" :key="kate.id" :value="kate.id">
              {{ kate.nama }}
            </option>
          </select>
          <small class="text-danger" v-if="invalid.kategori"
            >silahkan pilih kategori</small
          >
        </div>
        <!-- <div class="form-group">
            <label for="exampleFormControlSelect1">Tipe Telepon *</label>
            <select class="form-control" placeholder="Tipe Telepon">
              <option>Handphone</option>
              <option>Kantor</option>
              <option>Rumah</option>
              <option>Lainya</option>
            </select>
          </div> -->
        <div class="form-group mt-3">
          <label class="text-white" for="exampleFormControlSelect1"
            >Telepon *</label
          >
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">+62</span>
            </div>
            <input
              type="number"
              class="form-control"
              :class="invalid.telepon"
              id="telfon"
              ref="telepon"
              aria-describedby=""
              placeholder="Nomor Telepon"
              v-model="telepon"
            />
          </div>
          <small class="text-danger" v-if="invalid.telepon"
            >silahkan isi nomor telepon</small
          >
        </div>
        <div class="form-group mt-3">
          <label class="text-white" for="exampleFormControlSelect1"
            >Email *</label
          >
          <input
            type="email"
            class="form-control"
            :class="invalid.email"
            id="inputEmail"
            required
            aria-describedby="emailHelp"
            placeholder="Email"
            v-model="email"
          />
        </div>
        <div class="form-group mt-3">
          <label class="text-white" for="exampleFormControlSelect1"
            >Tipe Alamat *</label
          >
          <select
            class="form-control"
            :class="invalid.typeAlamat"
            ref="typealamat"
            placeholder="Tipe alamat"
            v-model="tipeAlamat"
          >
            <option value="KLINIK">klinik</option>
            <option value="KERJA">Kantor</option>
            <option value="RUMAH">Rumah</option>
            <option value="PRAKTEK_PRIBADI">Praktek Pribadi</option>
            <option value="RUMAH_BERSALIN">Rumah Bersalin</option>
            <option value="RUMAH_SAKIT">Rumah Sakit</option>
          </select>
          <small class="text-danger" v-if="invalid.typeAlamat"
            >silahkan pilih type alamat</small
          >
        </div>
        <div class="form-group mt-3 fullWidth">
          <label class="text-white" for="exampleFormControlSelect1"
            >Alamat *</label
          >
          <input
            type=""
            class="form-control"
            :class="invalid.alamat"
            aria-describedby=""
            ref="alamat"
            placeholder="alamat"
            v-model="alamat"
          />
          <small class="text-danger" v-if="invalid.alamat"
            >silahkan isi alamat</small
          >
        </div>
        <div class="form-group mt-3 fullWidth">
          <label class="text-white" for="exampleFormControlSelect1"
            >Jalan *</label
          >
          <input
            type=""
            class="form-control"
            :class="invalid.jalan"
            aria-describedby=""
            ref="jalan"
            placeholder="Jalan"
            v-model="jalan"
          />
          <small class="text-danger" v-if="invalid.jalan"
            >silahkan isi jalan</small
          >
        </div>
<!-- 
        <div class="form-group mt-3">
          <label class="text-white" for="exampleFormControlSelect1"
            >Username *</label
          >
          <input
            type=""
            class="form-control"
            :class="invalid.username"
            aria-describedby="emailHelp"
            ref="username"
            placeholder="UserName"
            v-model="userName"
            pattern="^[_.@a-z0-9-]*$"
          />
          <small class="form-text text-light">{{ getWarn }}</small>
        </div> -->
        <!-- <div class="form-group mt-3">
          <label class="text-white" for="exampleFormControlSelect1"
            >Password *</label
          >
          <input
            type="password"
            class="form-control"
            :class="invalid.password"
            placeholder="Password"
            ref="password"
            v-model="password"
          />
          <small class="form-text text-light">{{ passwordWarn }}</small>
        </div>
        <div class="form-group mt-3">
          <label class="text-white" for="exampleFormControlSelect1"
            >Konfirmasi Password *</label
          >
          <input
            type="password"
            class="form-control"
            :class="invalid.repassword"
            ref="repassword"
            placeholder="Konfirmasi Password"
            v-model="Konpassword"
          />
        </div> -->
        <div class="fullWidth">
          <button
            type="submit"
            class="btn btn-light mt-3 btn-sign-in"
            style="
              width: 20vw;
              background-color: rgba(255, 255, 255, 0.5);
              color: white;
            "
          >
            <template v-if="loading">
              <div
                class="spinner-border spinner-border-sm text-primary"
                role="status"
              >
                <span class="sr-only"></span>
              </div>
              <span class="span-loading">Loading</span>
            </template>
            <h5 class="mb-0" v-if="!loading">Register</h5>
          </button>
          <small class="form-text text-light">{{ cannotEmpty }}</small>
        </div>
      </form>
    </div>

    <!-- <label class="text-light float-right mt-2" @click.prevent="loginForm == 'verifikasi'">verifikasi sms?</label> -->
  </div>
</template>

<script>
import axios from "@/axios";
import { mapMutations } from "vuex";

export default {
  mounted() {
    const tok = localStorage.getItem('token')
    if (tok) {
      let actual = {}
      try {
        actual = JSON.parse(atob(tok.split(".")[1]));
      } catch {
        // 
      }
      if (actual.name) this.namaDepan = actual.given_name;
      if (actual.family_name) this.namaBelakang = actual.family_name
      if (actual.preferred_username && (/^62|^08/).test(actual.preferred_username) && actual.preferred_username.length > 6)
        this.telepon = actual.preferred_username.replace(/^(08|628)/, "8");
      if (actual.email) this.email = actual.email;
    }
    this.getKategories();
  },
  data() {
    return {
      loading: false,
      namaDepan: "",
      namaBelakang: "",
      kategories: [],
      kategori: "",
      telepon: "",
      email: "",
      tipeAlamat: "",
      alamat: "",
      jalan: "",
      // userName: "",
      // password: "",
      // Konpassword: "",
      isDisabled: true,
      invalid: {
        namaDepan: "",
        namaBelakang: "",
        kategori: "",
        telepon: "",
        email: "",
        alamat: "",
        typeAlamat: "",
        jalan: "",
        // username: "",
        // password: "",
        repassword: "",
      },
    };
  },
  computed: {
    cannotEmpty() {
      if (
        this.kategori ||
        this.email ||
        this.tipeAlamat ||
        this.jalan ||
        this.tipeAlamat ||
        this.telepon
      ) {
        return "*Pastikan Semua Kolom Terisi";
      }
      return "";
    },
    // getWarn() {
    //   let reg = /^[_.@a-z0-9-]*$/.test(this.userName);
    //   if (this.userName.length < 3) {
    //     this.isDisabled = true;
    //     return "UserName harus lebih dari 3 huruf";
    //   } else if (!reg) {
    //     this.isDisabled = true;
    //     return "Inputan ini hanya boleh mengandung huruf lowerCase, angka dan karakter _(underscore) .(titik) @(at) -(strip).";
    //   }
    //   this.isDisabled = false;
    //   return "";
    // },
    // passwordWarn() {
    //   if (this.password.length < 8) {
    //     this.isDisabled = true;
    //     return "Password minimal 8 huruf";
    //   } else if (this.password !== this.Konpassword) {
    //     this.isDisabled = true;
    //     return "Password dan konfirmasi password tidak sama";
    //   }
    //   this.isDisabled = false;
    //   return "";
    // },
  },
  watch: {
    namaDepan(val) {
      if (val && this.invalid.namaDepan) {
        this.invalid.namaDepan = "";
      }
    },
    namaBelakang(val) {
      if (val && this.invalid.namaBelakang) {
        this.invalid.namaBelakang = "";
      }
    },
    kategori(val) {
      if (val && this.invalid.kategori) {
        this.invalid.kategori = "";
      }
    },
    telepon(val) {
      if (val && this.invalid.telepon) {
        this.invalid.telepon = "";
      }
    },
    tipeAlamat(val) {
      if (val && this.invalid.typeAlamat) {
        this.invalid.typeAlamat = "";
      }
    },
    alamat(val) {
      if (val && this.invalid.alamat) {
        this.invalid.alamat = "";
      }
    },
    jalan(val) {
      if (val && this.invalid.jalan) {
        this.invalid.jalan = "";
      }
    }
  },
  methods: {
    ...mapMutations({
      'changeIsRegister': 'SET_ISREGISTER'
    }),
    checkInvalid() {
      let focus_form;
      if (!this.jalan) {
        focus_form = "jalan";
        this.invalid.jalan = "is-invalid";
      }
      if (!this.alamat) {
        focus_form = "alamat";
        this.invalid.alamat = "is-invalid";
      }
      if (!this.tipeAlamat) {
        focus_form = "typealamat";
        this.invalid.typeAlamat = "is-invalid";
      }
      if (!this.telepon) {
        focus_form = "telepon";
        this.invalid.telepon = "is-invalid";
      }
      if (!this.kategori) {
        focus_form = "kategori";
        this.invalid.kategori = "is-invalid";
      }
      if (!this.namaBelakang) {
        focus_form = "nambelakang";
        this.invalid.namaBelakang = "is-invalid";
      }
      if (!this.namaDepan) {
        focus_form = "namdep";
        this.invalid.namaDepan = "is-invalid";
      }
      if (focus_form) {
        this.$refs[focus_form].focus();
        return true;
      } 
      // else if (this.isDisabled) return true;
      return false;
    },
    registrasis() {
      const isError = this.checkInvalid()
      if (isError) {
        return false;
      }
      this.loading = true;
      let payload = {
        kategoriId: this.kategori,
        email: this.email,
        namaDepan: this.namaDepan,
        namaBelakang: this.namaBelakang,
        persetujuanEula: true,
        alamat: [
          {
            id: null,
            nama: this.alamat,
            jalan: this.jalan,
            tipe: this.tipeAlamat,
            pemilikAlamatId: null,
          },
        ],
        telepon: [
          {
            id: null,
            kodeKonfirmasi: null,
            pemilikTeleponId: null,
            nomor: `62${this.telepon}`,
            tipe: "HP_PRIMARY",
          },
        ]
      };

      axios
        .post(`/api/registrasis`, payload)
        .then(({ data }) => {
          this.regisId = data.id;
          const sessionStorageItem = {
            regisId: this.regisId,
            telepon: this.telepon,
          };
          sessionStorage.setItem(
            "verified",
            JSON.stringify(sessionStorageItem)
          );
          this.password = "";
          this.Konpassword = "";
          this.$toast.success("Registrasi Berhasil");
          this.changeIsRegister(true);
          this.$router.push({ name: "home" });
        })
        .catch((err) => {
          if (err.response.data.status == 400) {
            this.$toast.error(err.response.data.title);
            if (err.response.data.errorKey == "InvalidDigitPhoneNumber") {
              this.invalid.telepon = "is-invalid";
              this.$refs.telepon.focus();
            } else if (err.response.data.message == "error.userexists") {
              this.invalid.username = "is-invalid";
              this.$refs.username.focus();
            }
          } else {
            let msg = err.response.data ? err.response.data.title : err.msg;
            this.$toast.error(msg);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getKategories() {
      axios
        .get(`/api/kategoris`)
        .then(({ data }) => {
          this.kategories = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>